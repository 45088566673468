import { type FC } from 'react'

import Icons from 'assets/icons'
import { type Theme } from 'lib'
import { DataAvatar } from 'ui'

import { type UnitModel } from '../types'

interface Props {
    record: Pick<UnitModel, 'photo' | 'archived'>
}

const MainIcon = Icons.LocalShippingOutlined

const unitAvatarColor = (isArchived: boolean) =>
    isArchived ? (theme: Theme) => theme.palette.text.primary : undefined

const DefaultIcon: FC<{ isArchived?: boolean }> = ({ isArchived }) => {
    return <MainIcon sx={isArchived ? { opacity: 0.54 } : undefined} />
}

const Avatar: FC<Props> = ({ record }) => (
    <DataAvatar
        imageSrc={record.photo}
        defaultImage={<MainIcon />}
    />
)

export const avatar = {
    source: 'photo' satisfies keyof UnitModel,
    label: 'Avatar',
    Icon: MainIcon,
    ActiveIcon: Icons.LocalShipping,
    DefaultIcon,
    Value: Avatar,
    unitAvatarColor,
}
