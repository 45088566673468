import { type FC } from 'react'

import {
    type CardListConfig,
    type DatagridColumnsProps,
    type FilterConfig,
    List,
    ListBase,
    type ListBulkActions,
    ListFilterDateRangeValueInput,
    ListFilterValueInput,
    type ListSortContentProps,
} from 'components'
import { urls } from 'configs'
import {
    multiselectAction,
    ResourceContextProviderWithClearEffect,
    type SortPayload,
    createResource,
} from 'core'
import { Navigate, formatDate } from 'lib'
import { type IntegrationType, useTelematics, type TelematicsUnitModel } from 'resources/telematics'
import { PageContent, Stack } from 'ui'
import { capitalize, capitalizeWithLowerCase } from 'utils'

import { SyncedStateProvider } from './SyncedStateContext'
import SyncOffManyFromListAction from './actions/syncOffManyFromListAction'
import SyncOnManyFromListAction from './actions/syncOnManyFromListAction'
import FleetpalStatus from './components/FleetpalStatus'
import { IntegrationsUnitListFooter } from './components/IntegrationsUnitListFooter'
import ToSyncSwitch from './components/ToSyncSwitch'

const defaultSort: SortPayload<TelematicsUnitModel> = {
    field: 'created',
    order: 'DESC',
}

// Capitalize every word in a string that is separated by empty spaces.
// It is necessary because after replacing '_' with ' ' in Equipment Types that
// come from the API in snake case, words in them start with a lowercase letter.
// For example, 'auto_hauler' after replacing it becomes 'auto hauler,' but it needs to be 'Auto Hauler'.
const capitalizeEveryWord = (words: string) => {
    return words.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
}

const bulkActions: ListBulkActions = () => [
    <SyncOnManyFromListAction key="sync-on-many" />,
    <SyncOffManyFromListAction key="sync-off-many" />,
]

interface IntegrationsUnitListProps {
    dialog?: boolean
    provider: string
    onClose?: () => void
    HeaderComponent: FC<{ integration: IntegrationType }>
}

const IntegrationsUnitList: FC<IntegrationsUnitListProps> = ({
    dialog,
    provider,
    onClose,
    HeaderComponent,
}) => {
    const { telematics, list } = useTelematics()
    const integration: IntegrationType = telematics[provider]
    if (!(dialog || integration?.isActive)) {
        if (list.isLoading) {
            return null
        }
        return <Navigate to={urls.integrations} />
    }

    const listResource = createResource({
        name: 'telematics-vehicle',
        resource: 'telematics/' + integration.provider + '/vehicles',
    })

    const sortCfg: ListSortContentProps<TelematicsUnitModel> = {
        sortBy: [
            { id: 'number', label: 'Unit' },
            {
                id: 'created',
                label: 'Created on',
            },
            { id: 'status', label: 'Activity' },
            { id: 'equipmentCategory', label: 'Equipment Type' },
            { id: 'vin', label: 'VIN' },
            { id: 'licensePlate', label: 'License Plate' },
            { id: 'model', label: 'Model' },
            { id: 'modelYear', label: 'Model Year' },
        ],
    }

    const filtersCfg: FilterConfig<TelematicsUnitModel> = {
        filters: [
            { id: 'number', label: 'Unit' },
            {
                id: 'created',
                label: 'Created on',
                filterType: 'range',
                renderComponent: (props) => <ListFilterDateRangeValueInput {...props} />,
            },
            {
                id: 'status',
                label: 'Activity',
                renderComponent: (params) => (
                    <ListFilterValueInput
                        {...params}
                        makeItemLabel={(option) => capitalizeWithLowerCase(String(option.id))}
                    />
                ),
            },
            {
                id: 'equipmentCategory',
                label: 'Equipment Type',
                renderComponent: (params) => (
                    <ListFilterValueInput
                        {...params}
                        makeItemLabel={(option) =>
                            capitalizeEveryWord(String(option.id).replace('_', ' '))
                        }
                    />
                ),
            },
            { id: 'vin', label: 'VIN' },
            { id: 'licensePlate', label: 'License Plate' },
            { id: 'model', label: 'Model' },
            { id: 'modelYear', label: 'Model Year' },
        ],
    }

    const columnsCfg: DatagridColumnsProps<TelematicsUnitModel> = {
        resetColumns: {},
        columns: [
            { field: 'number', headerName: 'Unit', flex: 1 },
            {
                field: 'created',
                headerName: `Created on (${capitalize(provider)})`,
                flex: 1,
                renderCell: ({ value }) =>
                    formatDate(value, (dateFormats) => dateFormats.shortenedDate),
            },
            {
                field: 'status',
                headerName: 'Activity',
                valueFormatter: ({ value }) => capitalizeWithLowerCase(value),
            },
            {
                field: 'equipmentCategory',
                headerName: 'Equipment Type',
                valueFormatter: ({ value }) => capitalizeEveryWord(value.replace('_', ' ')),
            },
            { field: 'vin', headerName: 'VIN', flex: 1 },
            { field: 'licensePlate', headerName: 'License Plate', flex: 1 },
            { field: 'model', headerName: 'Model', flex: 1 },
            { field: 'modelYear', headerName: 'Model Year', flex: 1 },
            {
                field: 'sync',
                headerName: dialog ? 'To Sync' : 'Syncing',
                renderCell: ({ row }) => (
                    <ToSyncSwitch
                        id={row.telematicsId}
                        toSync={row.sync}
                    />
                ),
            },
        ],
        actions: null,
    }

    const cardsCfg: CardListConfig<TelematicsUnitModel> = {
        titleSource: 'number',
        disableTitleLink: true,
        defaultImage: null,
        imageSource: null,
        details: [
            {
                source: 'created',
                label: `Created on (${provider})`,
                render: (value) => formatDate(value, (dateFormats) => dateFormats.shortenedDate),
            },
            {
                source: 'status',
                label: 'Activity',
                render: (value) => capitalizeWithLowerCase(value),
            },
            {
                source: 'equipmentCategory',
                label: 'Equipment Type',
                render: (value) => capitalizeEveryWord(value.replace('_', ' ')),
            },
            { source: 'vin', label: 'vin' },
            { source: 'licensePlate', label: 'license plate' },
            { source: 'model', label: 'model' },
            { source: 'modelYear', label: 'model year' },
            {
                source: 'sync',
                label: dialog ? 'To Sync' : 'Syncing',
                render: (v, record) => (
                    <ToSyncSwitch
                        id={record.telematicsId}
                        toSync={v}
                    />
                ),
            },
        ],
        actions: ({ id }, { children }) => [
            multiselectAction({
                children,
                id,
            }),
        ],
    }
    if (!dialog && Array.isArray(cardsCfg.details)) {
        cardsCfg.details.unshift({
            source: 'connectionStatus',
            label: 'Fleetpal Status',
            render: (value, record) => <FleetpalStatus status={value} />,
        })
        sortCfg.sortBy.splice(1, 0, {
            id: 'connectionStatus',
            label: 'Fleetpal Status',
        })
        filtersCfg.filters.splice(1, 0, {
            id: 'connectionStatus',
            label: 'Fleetpal Status',
            renderComponent: (params) => (
                <ListFilterValueInput
                    {...params}
                    inputText={(option) => option.id}
                    makeItemLabel={(option) => (
                        <FleetpalStatus
                            hideTooltip
                            status={option.id as TelematicsUnitModel['connectionStatus']}
                        />
                    )}
                />
            ),
        })
        columnsCfg.columns.splice(1, 0, {
            field: 'connectionStatus',
            headerName: 'Fleetpal Status',
            renderCell: ({ row }) => <FleetpalStatus status={row.connectionStatus} />,
        })
    }

    return (
        <ResourceContextProviderWithClearEffect value={listResource}>
            <SyncedStateProvider dialog={dialog}>
                <ListBase
                    sort={defaultSort}
                    disableSyncWithLocation={dialog}
                >
                    <Stack
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <HeaderComponent integration={integration} />
                        <PageContent
                            sx={(theme) => ({
                                backgroundColor: theme.palette.gray,
                                pb: dialog ? '18px' : '101px',
                            })}
                        >
                            <List
                                listFTUProps={{
                                    linkText: null,
                                }}
                                sortCfg={sortCfg}
                                columnsCfg={columnsCfg}
                                cardsCfg={cardsCfg}
                                filtersCfg={filtersCfg}
                                bulkActions={bulkActions}
                                disableManageColumns
                                disableExportButton
                            />
                        </PageContent>
                        <IntegrationsUnitListFooter
                            provider={provider}
                            dialog={dialog}
                            onClose={onClose}
                        />
                    </Stack>
                </ListBase>
            </SyncedStateProvider>
        </ResourceContextProviderWithClearEffect>
    )
}

export default IntegrationsUnitList
