import { type NoInfer } from 'appTypes'
import { type ListFilterChoice, type SortBy, type Column, type DataListDetails } from 'components'
import { type AuthStore } from 'core'
import { type ShopModel } from 'resourcesBase'

import name from './name'

const source = 'shop' as const
const label = 'Repair Shop'

const value = (record: ShopModel, withLink?: boolean) => {
    if (withLink) {
        return name.valueWithLink(record)
    }

    return name.value(record)
}

const tableColumn = ({
    dataToRecord,
    withLink,
}: {
    dataToRecord: (data) => ShopModel
    withLink?: boolean
}) =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => value(dataToRecord(row), withLink),
    }) as const satisfies Column

const dataCardRow = ({ dataToRecord }: { dataToRecord: (data) => ShopModel }) =>
    ({
        source,
        label,
        render: (_, data) => value(dataToRecord(data)),
    }) as const satisfies DataListDetails<any, any>

const filter = <Source extends string = 'shop'>({ id }: { id?: Source } = {}) =>
    ({
        id: (id || source) as NoInfer<Source>,
        label,
    }) as const satisfies ListFilterChoice

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

/**
 * Decides if shop related stuff will be displayed
 */
const withGuard = <T = any,>(auth: AuthStore, data: T): T | null => {
    return auth.shop ? null : data
}

const self = {
    value,
    source,
    label,
    tableColumn,
    dataCardRow,
    sort,
    filter,
    withGuard,
    allSelectedText: 'All Shops Selected',
}

export default self
