import { emptySelectValue } from 'core/data'
import {
    alpha,
    classes,
    cssVariables,
    formHelperTextHeight,
    type Components,
    type Theme,
    type ThemeOptionsConfig,
} from 'lib'
import {
    autocompleteClasses,
    backdropClasses,
    buttonBaseClasses,
    chipClasses,
    circularProgressClasses,
    drawerClasses,
    formControlLabelClasses,
    formHelperTextClasses,
    formLabelClasses,
    iconClasses,
    inputBaseClasses,
    inputLabelClasses,
    listItemIconClasses,
    menuItemClasses,
    outlinedInputClasses,
    svgIconClasses,
    typographyClasses,
} from 'ui'

export const componentStyleOverrides = (
    theme: ThemeOptionsConfig,
): Components<Theme> & {
    [key: string]: {
        styleOverrides?: any
        defaultProps?: any
        variants?: any
    }
} => {
    return {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    marginBottom: '16px',
                    '&:hover': {
                        backgroundColor: theme.palette.blue[50],
                    },
                    '&.MuiNavDrawerActive': {
                        color: theme.palette.primary.main,
                        backgroundColor: theme.palette.blue[50],
                        borderRadius: '4px',
                        [`& .${typographyClasses.body1}, & .${listItemIconClasses.root}`]: {
                            color: theme.palette.primary.main,
                        },
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    [`& .${menuItemClasses.root}`]: {
                        [`&[data-value="${emptySelectValue}"]`]: {
                            display: 'none !important',
                        },
                    },
                },
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    // 134% is the number needed for the scaled down width from 0.75 to become 100%, 134 * 0.75 ~=~ 100%

                    [`& .${inputLabelClasses.root}.${classes.focused}`]: {
                        maxWidth: 'calc(134% - 70px)',
                    },
                    [`.${formLabelClasses.filled}.${inputLabelClasses.root}`]: {
                        maxWidth: 'calc(134% - 70px)',
                    },
                    //
                    [`& .${outlinedInputClasses.root}.${classes.focused}`]: {
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            '&>legend': { maxWidth: '95%' },
                        },
                    },
                    [`.${formLabelClasses.filled} ~ .${outlinedInputClasses.root} > fieldset`]: {
                        '&>legend': { maxWidth: '95%' },
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true,
                autoComplete: 'off',
            },
            styleOverrides: {
                root: {
                    marginTop: '0px',
                    [`& .${inputBaseClasses.root}`]: {
                        background: 'white',
                    },
                    [`& .${inputBaseClasses.root} + .${formHelperTextClasses.root}`]: {
                        boxSizing: 'border-box',
                        marginTop: '0px',
                        paddingTop: '3px',
                        minHeight: formHelperTextHeight,
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    [`&.${classes.disabled}`]: {
                        [`& .${outlinedInputClasses.notchedOutline}`]: {
                            borderStyle: 'dotted',
                        },
                    },
                },
            },
        },
        MuiChip: {
            variants: [
                {
                    props: {
                        size: 'extraSmall',
                    },
                    style: {
                        height: '20px',
                        [`& .${chipClasses.label}`]: {
                            padding: '0px 7px',
                        },
                    },
                },
                {
                    props: {
                        color: 'default',
                    },
                    style: {
                        [`&.${chipClasses.clickable}:hover`]: {
                            backgroundColor: 'rgba(35, 52, 97, 0.14)',
                        },
                    },
                },
            ],
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    [`&.${inputLabelClasses.root}`]: {
                        color: 'rgba(75, 98, 144, 1)',
                        [`&.${classes.disabled}`]: {
                            color: 'rgba(0, 0, 0, 0.38)',
                        },
                        overflow: 'initial',
                        // 70px is the space needed for the dropdown icon and the asterisk simultaneously
                        maxWidth: 'calc(100% - var(--label-space, 35px))',

                        '& > span': {
                            overflow: 'hidden',
                            width: '100%',
                            display: 'inline-block',
                            textOverflow: 'ellipsis',

                            '&>span': {
                                position: 'absolute',
                                right: '-10px',
                                top: 0,
                            },
                        },
                    },
                    '& > span': {
                        color: 'inherit',
                    },
                    '& > span > span[aria-hidden="true"]': {
                        // from RA
                        color: theme.palette.error.main,
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    [`& .${buttonBaseClasses.root}.${classes.selected}`]: {
                        backgroundColor: alpha(theme.palette.grey[900], 0.08),
                    },
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '--label-space': '60px',
                },
                listbox: {
                    [`& li.${autocompleteClasses.option}`]: {
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        display: 'block',
                    },
                },
                option: {
                    '&[aria-disabled="true"]': {
                        // this is for create option
                        display: 'none !important',
                    },
                    [cssVariables.gap]: '16px',
                },
                paper: {
                    mb: '14px',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: ({ theme: muiTheme }) => ({
                    [`&:not(.${backdropClasses.invisible}):not(.backdrop-custom-bg)`]: {
                        backgroundColor: alpha(muiTheme.palette.text.primary, 0.54),
                    },
                }),
            },
        },
        MuiDrawer: {
            styleOverrides: {
                root: ({ theme: muiTheme }) => ({
                    [muiTheme.breakpoints.up(muiTheme.props.mobileViewBreakpoint)]: {
                        [` & +.${drawerClasses.root}:not(.backdrop-visible)`]: {
                            [`& .${backdropClasses.root}`]: {
                                opacity: '0 !important',
                            },
                        },
                    },
                }),
            },
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    [` & ~.${drawerClasses.root}`]: {
                        zIndex: 1300,
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'none',
                variant: 'body1',
            },
            styleOverrides: {
                root: {
                    textDecoration: 'none',
                    cursor: 'pointer',
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                title: ({ theme: muiTheme }) => ({
                    ...muiTheme.typography.subtitle2,
                }),
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& .show-on-hover': {
                        opacity: 0,
                    },
                    '&:hover .show-on-hover': {
                        opacity: 1,
                    },
                },
            },
        },
        MuiIconButton: {
            variants: [
                {
                    props: {
                        size: 'small',
                    },
                    style: {
                        padding: '4px',
                    },
                },
            ],
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: theme.palette.action.hovered,
                    },
                    [`&.${classes.selected}`]: {
                        backgroundColor: theme.palette.action.activeSelected,
                    },
                },
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    [`& .${menuItemClasses.root}`]: {
                        [cssVariables.gap]: '16px',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    whiteSpace: 'nowrap',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    [cssVariables.gap]: '10px',
                },
            },
        },

        RaSaveButton: {
            styleOverrides: {
                root: {
                    [`& .${svgIconClasses.root}, & .${iconClasses.root}, & .${circularProgressClasses.root}`]:
                        {
                            marginRight: '8px',
                        },
                    [`& .${svgIconClasses.root}, & .${iconClasses.root}`]: {
                        fontSize: '24px',
                    },
                },
            },
        },
        RaResettableTextField: {
            styleOverrides: {
                root: {
                    '& .RaResettableTextField-clearButton': {
                        marginRight: '4px',
                        [`&.${classes.disabled}`]: {
                            display: 'none',
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            borderRadius: '50%',
                        },
                        [`& .${svgIconClasses.root}`]: {
                            width: '20px',
                            height: '20px',
                        },
                    },
                },
            },
        },
        RaRadioButtonGroupInput: {
            styleOverrides: {
                root: {
                    [`& .${formControlLabelClasses.root}`]: {
                        // mui adds negative left margin to the radio/checkbox input group labels. Remove it
                        marginLeft: 0,
                    },
                },
            },
        },
    }
}
