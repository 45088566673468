import { useResourceContext, useShowContext } from 'react-admin'

import Icons from 'assets/icons'
import {
    CollapsibleContent,
    CollapsibleInfoCard,
    InfoCardDetails,
    InfoCardHeader,
    CollapsibleIconHolder,
    Gallery,
    GalleryItemGrid,
    RawGalleryItem,
} from 'components'
import { useCreateResourcePath, api, useConfirm, useNotify } from 'core'
import { Link, alpha, styled, globalClassNames, formatDate } from 'lib'
import { isDefectInProgress, type DefectModel } from 'resources/defects'
import { type UnitModel, isUnitArchived } from 'resources/units'
import { ck33Fields } from 'resources/vmrs'
import { woFields, WoRepairClassKeys, woResource } from 'resources/workOrders'
import {
    SvgIcon,
    Alert,
    Divider,
    type BoxProps,
    cardHeaderClasses,
    Stack,
    DataAvatar,
    InfoBadge,
    SimpleScrollbar,
    Typography,
    BoxContainer,
    Tooltip,
} from 'ui'
import { pathJoin } from 'utils'

import { type WorkOrderCreateMenuProps, AddWorkOrderMenu } from '../components'
import 'photoswipe/dist/photoswipe.css'

export const DriverPhotos = ({ files }: { files: string[] }) => {
    let content = <Alert severity="info">No Photos Added</Alert>
    if (files.length) {
        content = (
            <Gallery>
                <GalleryItemGrid>
                    {files.map((file, i) => {
                        return (
                            <RawGalleryItem
                                id={`${i}`}
                                key={i}
                                src={file}
                                sx={{
                                    height: '89px',
                                }}
                            />
                        )
                    })}
                </GalleryItemGrid>
            </Gallery>
        )
    }
    return (
        <Stack
            spacing="14px"
            mt="10px"
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    color="text.secondary"
                    variant="chartTitle"
                    component={BoxContainer}
                    gap="8px"
                >
                    Photos <InfoBadge badgeContent={files.length} />
                </Typography>
            </BoxContainer>
            {content}
        </Stack>
    )
}
interface DriverNoteProps extends Pick<DefectModel, 'authorName' | 'detectedOn'> {
    notes: string[]
}
export const DriverNotes = ({ notes, ...rest }: DriverNoteProps) => {
    let content = <Alert severity="info">No Notes Added</Alert>
    if (notes.length) {
        content = (
            <SimpleScrollbar
                sx={{
                    maxHeight: '144px',
                }}
            >
                <Stack spacing="10px">
                    {notes.map((text) => (
                        <Note
                            description={text}
                            {...rest}
                        />
                    ))}
                </Stack>
            </SimpleScrollbar>
        )
    }
    return (
        <Stack
            spacing="14px"
            mt="10px"
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    color="text.secondary"
                    variant="chartTitle"
                    component={BoxContainer}
                    gap="8px"
                >
                    Notes <InfoBadge badgeContent={notes.length} />
                </Typography>
            </BoxContainer>
            {content}
        </Stack>
    )
}
interface NoteProps extends BoxProps, Pick<DriverNoteProps, 'authorName' | 'detectedOn'> {
    description: DriverNoteProps['notes'][0]
}
export const Note = styled(({ authorName, detectedOn, description, ...props }: NoteProps) => {
    return (
        <Stack
            spacing="4px"
            {...props}
        >
            <Typography variant="chartTitle">{authorName}</Typography>
            <Typography
                variant="chartLabel"
                component={BoxContainer}
                gap="13ox"
                color="text.secondary"
            >
                <SvgIcon
                    component={Icons.CalendarTodayOutlined}
                    sx={{
                        width: '10px',
                        height: '10px',
                        marginRight: '4px',
                    }}
                />
                {formatDate(detectedOn, 'MMM dd yyyy hh:mm a')}
            </Typography>
            <Typography
                variant="chartsBody"
                sx={{ wordBreak: 'break-word' }}
            >
                {description}
            </Typography>
        </Stack>
    )
})`
    padding: 12px;
    gap: 4px;
    border-radius: 8px;
    background: ${({ theme }) => alpha(theme.palette.text.primary, 0.06)};
`

const UnitDvirDefectCard = (data: DefectModel) => {
    const { record: unit } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(unit)

    const createPath = useCreateResourcePath()
    const resource = useResourceContext()
    const notify = useNotify()

    const confirm = useConfirm()
    const isInProgress = isDefectInProgress(data)
    const { name, workOrderData, detectedOn, driverComment, id, authorName, photo } = data
    const woAttachConfig: WorkOrderCreateMenuProps = {
        options: {
            jobDisabledFields: {
                reasonForRepair: true,
            },
            jobDefaultValues: {
                name,
                photo0: data.photo,
                notes: driverComment
                    ? [
                          {
                              createdByData: {
                                  name: authorName,
                              },
                              text: driverComment,
                              created: detectedOn,
                              source: 'DEFECT',
                          },
                      ]
                    : [],
                complaint: data.complaint,
            },
            woDisabledFields: {
                unit: true,
            },
            woDefaultValues: {
                unit: unit.id,
                domicile: unit.domicile,
                repairPriorityClass: WoRepairClassKeys.NON_SCHEDULED,
            },
        },
        parentRecord: data,
        disabled: isArchived || isInProgress,
    }

    return (
        <CollapsibleInfoCard sameParent>
            <InfoCardHeader
                // TODO: make the header overflow hidden
                sx={{
                    [`.${cardHeaderClasses.content}`]: {
                        overflow: 'hidden',
                    },
                }}
                title={
                    <Tooltip title={name}>
                        <Typography
                            variant="subtitle2"
                            lineHeight="100%"
                            component="span"
                            className={globalClassNames.ellipsis}
                            color="text.primary"
                            mr="10px"
                        >
                            {name}
                        </Typography>
                    </Tooltip>
                }
                actions={(params, { children }) => [
                    children({
                        title: 'Manual Resolve',
                        Icon: Icons.HowToRegOutlined,
                        key: 'resolve',
                        onClick: () =>
                            confirm({
                                title: 'Are you sure you want to resolve this defect manually?',
                                content: (
                                    <Typography
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        Resolved defects will no longer appear on this list.
                                        <br />
                                        {isInProgress ? (
                                            <span>
                                                Its corresponding{' '}
                                                {
                                                    <Typography
                                                        color="primary"
                                                        variant="body2"
                                                        component={Link}
                                                        to={
                                                            createPath({
                                                                resource: woResource.resource,
                                                                id: workOrderData.id,
                                                                type: 'edit',
                                                            }) + '/jobs'
                                                        }
                                                        state={{ forceRedirect: true }}
                                                    >
                                                        jobs ({workOrderData.number})
                                                    </Typography>
                                                }{' '}
                                                will not be deleted.
                                            </span>
                                        ) : null}
                                    </Typography>
                                ),
                                confirmButtonProps: {
                                    color: 'error',
                                    variant: 'text',
                                    children: 'MANUAL RESOLVE',
                                    startIcon: <Icons.HowToRegOutlined />,
                                },
                                onConfirm: async () => {
                                    await api.post(pathJoin(resource, String(id), 'resolve'), {
                                        resolvedOn: new Date(),
                                    })
                                    notify('Successfully Resolved!')
                                },
                            }),
                    }),
                    <AddWorkOrderMenu<DefectModel>
                        {...woAttachConfig}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        key="add-wo"
                        renderToggler={(open) =>
                            children({
                                title: (
                                    <BoxContainer
                                        justifyContent="space-between"
                                        component="span"
                                        width="140px"
                                    >
                                        Add to WO <Icons.ArrowRightOutlined fontSize="small" />
                                    </BoxContainer>
                                ),

                                Icon: woFields.avatar.Icon,
                                onClick: open,
                                disabled: isArchived || isInProgress,
                                disableCloseOnClick: true,
                            })
                        }
                    />,
                ]}
                subheader={
                    <Typography variant="chartsBody">
                        {isInProgress ? 'In Progress' : 'Unresolved'}
                    </Typography>
                }
                avatar={
                    <DataAvatar
                        color={({ palette }) => palette[isInProgress ? 'primary' : 'error'].main}
                        defaultImage={
                            isInProgress ? <Icons.DvirInProgress /> : <Icons.DvirUnresolved />
                        }
                    />
                }
            />
            <InfoCardDetails
                propRecord={data}
                details={[
                    {
                        label: 'Detected On',
                        source: ({ detectedOn }) => formatDate(detectedOn, 'MMM dd yyyy hh:mm a'),
                    },
                    {
                        label: 'Component',
                        source: ({ componentData }) => ck33Fields.self.value(componentData),
                    },
                    typeof data.complaintText === 'string'
                        ? {
                              label: 'Complaint',
                              source: ({ complaintText }) =>
                                  complaintText ? (
                                      <Tooltip title={complaintText}>
                                          <div className={globalClassNames.ellipsis}>
                                              {complaintText}
                                          </div>
                                      </Tooltip>
                                  ) : null,
                          }
                        : null,
                    {
                        label: 'Work orders',
                        source: ({ workOrderData }) =>
                            isInProgress ? (
                                woFields.self.linkValue(workOrderData)
                            ) : (
                                <AddWorkOrderMenu<DefectModel> {...woAttachConfig} />
                            ),
                    },
                ]}
            />
            <Divider
                sx={{
                    margin: '10px 0px',
                }}
            />
            <BoxContainer gap="8px">
                <CollapsibleIconHolder
                    name="comment"
                    title="Comments (1)"
                    titleOnDisabled="No Driver Comments Added"
                    disabled={!driverComment}
                >
                    <Icons.CommentOutlined fontSize="small" />
                </CollapsibleIconHolder>
                <CollapsibleIconHolder
                    name="files"
                    title="Photos (1)"
                    titleOnDisabled="No Photos Added"
                    disabled={!photo}
                >
                    <Icons.AttachFileOutlined fontSize="small" />
                </CollapsibleIconHolder>
            </BoxContainer>
            <CollapsibleContent name="comment">
                <DriverNotes
                    notes={driverComment ? [driverComment] : []}
                    detectedOn={detectedOn}
                    authorName={authorName}
                />
            </CollapsibleContent>
            <CollapsibleContent name="files">
                <DriverPhotos files={photo ? [photo] : []} />
            </CollapsibleContent>
        </CollapsibleInfoCard>
    )
}
export default UnitDvirDefectCard
